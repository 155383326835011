import { Component, computed, Signal } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { DocumentRequestPayload, DocumentRequirements, RemainingDocumentRequirement } from '@app/app/interfaces/document-requirements.model';
import { DocumentsState } from '@app/app/store/documents/documents.state';
import { Store } from '@ngxs/store';
import { DocumentRequirementDialogComponent } from '../documents/document-requirement-dialog/document-requirement-dialog.component';
import { CommonModule } from '@angular/common';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';
import { ApplicationDetailsState } from '@app/app/store/application-details/application-details.state';
import { ApplicationDetails } from '@app/app/interfaces/application-details.model';
import { formatDocumentRequestsPayload } from './document-requirements-helpers';
import { DocumentsActions as DA } from '@app/app/store/documents/documents.actions';
import { DeleteDocumentRequirementDialogComponent } from '../documents/document-requirement-dialog/document-requirement-delete-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
@Component({
  selector: 'app-document-requirements',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule
  ],
  templateUrl: './document-requirements.component.html',
  styleUrl: './document-requirements.component.scss'
})
export class DocumentRequirementsComponent {
  documentRequirements: Signal<DocumentRequirements>;
  uploadedButNotAccepted: Signal<RemainingDocumentRequirement[]>;
  notUploaded: Signal<RemainingDocumentRequirement[]>;
  canAddDocumentRequirements: Signal<boolean>;
  applicationDetails: Signal<ApplicationDetails>;
  acceptedFraction: Signal<string>;
  hasRequirements: Signal<boolean>;

  constructor(
    private dialog: MatDialog,
    private _store: Store
  ) {
    this.documentRequirements = this._store.selectSignal(DocumentsState.documentRequirements);
    this.canAddDocumentRequirements = this._store.selectSignal(SaasFeaturesState.saasPermitted('canAddDocumentRequirements'));
    this.applicationDetails = this._store.selectSignal(ApplicationDetailsState.applicationDetails);

    this.hasRequirements = computed(() => {
      return (
        this.documentRequirements().satisfied.length > 0 ||
        this.documentRequirements().remaining.length > 0
      );
    });

    this.acceptedFraction = computed(() => {
      if (!this.hasRequirements()) {
        return 'No requirements available';
      }

      return `(${this.documentRequirements().satisfied.length}/${
        this.documentRequirements().satisfied.length +
        this.documentRequirements().remaining.length
      } Accepted)`;
    });

    this.notUploaded = computed(() => {
      return this.documentRequirements().remaining.filter((dr) => !dr.isSatisfied);
    });

    this.uploadedButNotAccepted = computed(() => {
      return this.documentRequirements().remaining.filter((dr) => dr.isSatisfied);
    });
  }

  openDocumentRequirementDialog(event): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(DocumentRequirementDialogComponent, {
      position: { top: '10%' },
      width: '408px',
      disableClose: true,
    });

    dialogRef.componentInstance.onSave.subscribe({
      next: (payload: DocumentRequestPayload) => {
        const dealId = this.applicationDetails().deal.id;
        const documentRequestsPayload = formatDocumentRequestsPayload(payload)
        this._store.dispatch(new DA.PostDocumentRequirements(dealId, documentRequestsPayload));
      }
    });
  }

  handleRequirementDelete(event, requiredDocument): void {
      event.stopPropagation();
      this.dialog.open(DeleteDocumentRequirementDialogComponent, {
        width: '375px',
        data: { requiredDocument, dealId: this.applicationDetails().deal.id }
      });
  }
}
